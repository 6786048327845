import { combineReducers } from "redux";

function selected(state = "pt", action) {
  switch (action.type) {
    case "SET_SELECTED_LANGUAGE":
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  selected,
});
