import { combineReducers } from "redux";

function isOpen(state = true, action) {
  switch (action.type) {
    case "OPEN_LANDING_PAGE":
      return true;
    case "CLOSE_LANDING_PAGE":
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  isOpen,
});
