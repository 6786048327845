import { connect } from "react-redux";
import { toggleFilter, selectFilter } from "../../redux/filter/actions";
import FilterItems from "./FilterItems";

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.filter.isOpen,
  selectedFilter: state.filter.selected,
  selectedCategoryTag: state.drawer.selected.tag,
  language: state.language.selected,
});

const mapDispatchToProps = {
  toggleFilter,
  selectFilter,
};

const FilterItemsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterItems);

export default FilterItemsContainer;
