import { connect } from "react-redux";
import { fetchMenu } from "../../redux/requests/actions";
import { toggleDrawer } from "../../redux/drawer/actions";
import { selectItem } from "../../redux/item/actions";
import AppHeader from "./AppHeader";

const mapStateToProps = (state, ownProps) => ({
  language: state.language.selected,
});

const mapDispatchToProps = {
  fetchMenu,
  toggleDrawer,
  selectItem,
};

const AppHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);

export default AppHeaderContainer;
