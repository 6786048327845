import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";
import { StyledMenuscreenTheme, MuiMenuscreenTheme } from "./components";
import { store, persistor } from "./redux";
import GlobalFonts from "./assets/fonts";
import Layout from "./components/Layout";

const App = ({ isLandingPageOpen }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={"Loading..."} persistor={persistor}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={StyledMenuscreenTheme}>
            <MuiThemeProvider theme={MuiMenuscreenTheme}>
              <GlobalFonts />
              <Layout />
            </MuiThemeProvider>
          </ThemeProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
