import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AppHeader from "../AppHeader";
import MenuItems from "../MenuItems";
import LandingPage from "../LandingPage";

const MainContent = styled.div`
  min-height: 100vh;
  padding: 12px;
  flex: 1;
  height: calc(100% - 80px);
  background-color: ${(props) => props.theme.background};
  & > .MuiPaper-root {
    box-shadow: none;
  }
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
`;

const Layout = ({ isLandingPageOpen }) => {
  return (
    <>
      {isLandingPageOpen ? (
        <LandingPage />
      ) : (
        <>
          <AppHeader />
          <MainContent>
            <MenuItems />
          </MainContent>
        </>
      )}
    </>
  );
};

Layout.propTypes = {
  isLandingPageOpen: PropTypes.bool.isRequired,
};

export default Layout;
