import React, { useEffect, useState, createRef } from "react";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import {
  List,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Fab,
  Grow,
  Paper,
} from "@material-ui/core";
import { ErrorOutlineSharp } from "@material-ui/icons";

import FilterListIcon from "@material-ui/icons/FilterList";
import FilterItems from "../FilterItems";
import Item from "../Item";
import t from "../../assets/translations";

import noImage from "../../assets/images/no_image.png";
import shadow from "../../assets/images/shadow.png";

const imageBaseURL = `https://plataforma.ementasdigitais.pt/media/cache/570x410/uploads/images/`;
const filterableCategories = ["wines", "sake"];
const scrollToRef = (ref) => {
  if (ref) {
    window.scrollTo(0, ref.current.offsetTop - window.innerHeight / 4);
  } else {
    window.scrollTo(0, 0);
  }
};

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 275px;
`;

const Shadow = styled.img.attrs({ src: shadow })`
  width: 100%;
  display: flex;
  align-self: center;
  margin: -8px auto 16px auto;
`;

const StyledList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 16px 0;
`;

const StyledCard = styled(Card)`
  &.MuiCard-root {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
`;
const StyledCardMedia = styled(CardMedia).attrs({ component: "img" })`
  &.MuiCardMedia-root {
    height: 200px;
  }

  &.MuiCardMedia-img {
    object-fit: contain;
    transform: scale(1.1);
  }
`;

const StyledCardContent = styled(CardContent)`
  height: 112px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const bounce = keyframes`
	0%, 50%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	25%, 75% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
`;

const StyledFabBottom = styled(Fab)`
  position: fixed;
  z-index: 1;
  bottom: 16px;
  right: 16px;
  background-color: ${(props) => props.theme.background};
  animation: ${bounce} 2s ease-in-out;

  @media (orientation: landscape) {
    bottom: 24px;
    right: 24px;
  }
`;

const ItemName = styled(Typography).attrs({ variant: "h3" })`
  ${(props) => props.theme.itemTitleFont};
`;

const ItemPrices = styled(Typography).attrs({ variant: "body1" })`
  ${(props) => props.theme.itemBodyFont};
`;

const ItemMore = styled(Typography).attrs({ variant: "body1" })`
  ${(props) => props.theme.itemBodyFont};
  color: ${(props) => props.theme.lightGrey};
`;

const ItemDetail = styled(Paper)`
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  z-index: 10;
  height: calc(100vh - 56px);
`;

const NoItemMessage = styled.div`
  width: calc(100% - 64px);
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 32px;
`;

const StyledErrorIcon = styled(ErrorOutlineSharp)`
  width: 64px;
  height: 64px;
  fill: ${(props) => props.theme.lightGrey};
`;

const ErrorMessage = styled(Typography).attrs({ variant: "body1" })`
  ${(props) => props.theme.itemBodyFont};
  color: ${(props) => props.theme.lightGrey};
  font-size: 18px;
`;

const MenuItems = ({
  isDrawerOpen,
  categoryItems,
  selectedCategory,
  selectedFilter,
  toggleFilter,
  selectItem,
  selectedItem,
  language,
}) => {
  const [items, setItems] = useState([]);

  const refs = items.reduce((acc, curr) => {
    acc[curr.id] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (
      selectedFilter.type === "all_types" &&
      selectedFilter.region === "all_regions"
    ) {
      setItems(categoryItems);
    } else {
      let filteredItems = [];
      if (selectedFilter.type !== "all_types") {
        filteredItems = categoryItems.filter((i) =>
          i.categories.some((c) => c.tag === selectedFilter.type)
        );
      }

      if (
        selectedCategory.tag === "wines" &&
        selectedFilter.region !== "all_regions"
      ) {
        if (selectedFilter.type !== "all_types") {
          filteredItems = categoryItems
            .filter((i) =>
              i.categories.some((c) => c.tag === selectedFilter.type)
            )
            .filter((i) =>
              i.categories.some((c) => c.tag === selectedFilter.region)
            );
        } else {
          filteredItems = categoryItems.filter((i) =>
            i.categories.some((c) => c.tag === selectedFilter.region)
          );
        }
      }

      setItems(filteredItems);
    }
    window.scrollTo(0, 0);
  }, [setItems, categoryItems, selectedFilter, selectedCategory]);

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = noImage;
  };

  const getPrices = (price, otherPrices) => {
    let prices = ``;

    if (price > 0) {
      prices += `€ ${price.toFixed(2)}`;
    }

    if (
      Object.keys(otherPrices).length > 0 &&
      Object.values(otherPrices).reduce((acc, curr) => acc + parseFloat(curr))
    ) {
      prices += Object.values(otherPrices)
        .map((p, i) =>
          parseFloat(p) > 0
            ? ` ${i === 0 && (!price || price <= 0) ? `` : `/ `}€ ${parseFloat(
                p
              ).toFixed(2)}`
            : ``
        )
        .reduce((acc, curr) => acc + curr);
    }

    return prices;
  };

  return (
    <>
      {items.length > 0 ? (
        <>
          <TouchScrollable>
            <Grow in={selectedItem !== null}>
              <ItemDetail>
                {selectedItem ? (
                  <Item
                    data={selectedItem}
                    onClose={() => {
                      selectItem(null);
                      scrollToRef(refs[selectedItem.id]);
                    }}
                  />
                ) : null}
              </ItemDetail>
            </Grow>
          </TouchScrollable>
          <StyledList>
            {items.map((item) => (
              <CardWrapper ref={refs[item.id]} key={item.id}>
                <StyledCard>
                  <CardActionArea onClick={() => selectItem(item)}>
                    <StyledCardMedia
                      image={`${imageBaseURL}${item.image}`}
                      onError={handleImageError}
                      title={item.name}
                    />
                    <StyledCardContent>
                      <ItemName>{item.name}</ItemName>
                      <ItemPrices>
                        {getPrices(item.price, item.other_prices)}
                      </ItemPrices>
                      <ItemMore>{t[language].menu.buttons.more}</ItemMore>
                    </StyledCardContent>
                  </CardActionArea>
                </StyledCard>
                <Shadow />
              </CardWrapper>
            ))}
          </StyledList>
          <ScrollLock isActive={isDrawerOpen || selectedItem !== null} />
        </>
      ) : (
        <ScrollLock>
          <NoItemMessage>
            <>
              <StyledErrorIcon />
              <ErrorMessage>
                {t[language].menu.disclaimers.no_items}
              </ErrorMessage>
            </>
          </NoItemMessage>
        </ScrollLock>
      )}
      {filterableCategories.includes(selectedCategory.tag) ? (
        <>
          <StyledFabBottom onClick={() => toggleFilter(true)}>
            <FilterListIcon />
          </StyledFabBottom>
          <FilterItems />
        </>
      ) : null}
    </>
  );
};

MenuItems.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  categoryItems: PropTypes.array.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  language: PropTypes.string.isRequired,
};

export default MenuItems;
