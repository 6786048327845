import { combineReducers } from "redux";

function isOpen(state = false, action) {
  switch (action.type) {
    case "OPEN_FILTER":
      return true;
    case "CLOSE_FILTER":
      return false;
    default:
      return state;
  }
}

function selected(
  state = { type: "all_types", region: "all_regions" },
  action
) {
  switch (action.type) {
    case "SET_SELECTED_FILTER":
      return action.payload;
    case "RESET_FILTER":
      return { type: "all_types", region: "all_regions" };
    default:
      return state;
  }
}

export default combineReducers({
  isOpen,
  selected,
});
