import { combineReducers } from "redux";

function selected(state = null, action) {
  switch (action.type) {
    case "SET_SELECTED_ITEM":
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  selected,
});
