import { connect } from "react-redux";
import Layout from "./Layout";

const mapStateToProps = (state, ownProps) => ({
  isLandingPageOpen: state.landingPage.isOpen,
});

const mapDispatchToProps = {};

const LayoutContainer = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default LayoutContainer;
