import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fab,
} from "@material-ui/core";
import { Close, KeyboardBackspace } from "@material-ui/icons";
import styled from "styled-components";
import t from "../../assets/translations";
import noImage from "../../assets/images/no_image.png";
import shadow from "../../assets/images/shadow.png";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const imageBaseURL = `https://plataforma.ementasdigitais.pt/media/cache/570x410/uploads/images/`;

const Shadow = styled.img.attrs({ src: shadow })`
  width: 50%;
  display: flex;
  align-self: center;
  margin: 16px auto;
`;

const Image = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  margin-bottom: 16px;
`;

const ItemName = styled(Typography).attrs({ variant: "h3" })`
  ${(props) => props.theme.itemTitleFont};
`;

const ItemPrices = styled(Typography).attrs({ variant: "body1" })`
  ${(props) => props.theme.itemBodyFont};
`;

const ItemDescription = styled(Typography).attrs({ variant: "body1" })`
  ${(props) => props.theme.itemBodyFont};
  font-size: 14px;
`;

const StyleListItem = styled(ListItem)`
  &.MuiListItem-root {
    padding-bottom: 0;
  }
`;

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    ${(props) => props.theme.itemBodyFont};
    font-size: 14px;
    margin-top: 0;
  }
`;

const Wrapper = styled.div``;

const Body = styled.div`
  padding: 0 16px ${iOS ? 114 : 0}px 16px;
`;

const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 10px 0;
  border-color: ${(props) => props.theme.lightGrey};
`;

const StyledTableCell = styled(TableCell)`
  padding: 6px;
  font-family: Helvetica;
`;

const StyledTableHeaderCell = styled(StyledTableCell)`
  font-family: HelveticaBold;
  font-style: oblique;
`;

const StyledFabTop = styled(Fab)`
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
  background-color: ${(props) => props.theme.background};
  height: 36px;
  width: 36px;

  @media (orientation: landscape) {
    top: 24px;
    right: 24px;
  }
`;

const StyledFabExtended = styled(Fab).attrs({ variant: "extended" })`
  background-color: ${(props) => props.theme.white};
  border: 2px solid ${(props) => props.theme.lightGrey};
  box-shadow: none;
  margin: 24px auto;
  display: flex;
`;

const StyledArrow = styled(KeyboardBackspace)`
  fill: ${(props) => props.theme.lightGrey};
  width: 16px;
  height: 16px;
`;

const ButtonText = styled(Typography)`
  ${(props) => props.theme.drawerListFont};
  font-size: 12px;
  color: ${(props) => props.theme.lightGrey};
  margin-left: 6px;
`;

const Item = ({ data, onClose, language }) => {
  const ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, ref.current.offsetTop);
  }, []);

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = noImage;
  };

  const getPrices = (price, otherPrices) => {
    let prices = ``;

    if (price > 0) {
      prices += `€ ${price.toFixed(2)}`;
    }

    if (
      Object.keys(otherPrices).length > 0 &&
      Object.values(otherPrices).reduce((acc, curr) => acc + parseFloat(curr))
    ) {
      prices += Object.values(otherPrices)
        .map((p, i) =>
          parseFloat(p) > 0
            ? ` ${i === 0 && (!price || price <= 0) ? `` : `/ `}€ ${parseFloat(
                p
              ).toFixed(2)} ${
                t[language].menu.dosage[Object.keys(otherPrices)[i]]
              }`
            : ``
        )
        .reduce((acc, curr) => acc + curr);
    }

    return prices.length > 0
      ? `${prices} / ${t[language].menu.disclaimers.vat}`
      : prices;
  };

  return (
    <Wrapper ref={ref}>
      <StyledFabTop onClick={onClose}>
        <Close />
      </StyledFabTop>
      <Image
        src={`${imageBaseURL}${data.image}`}
        alt={""}
        onError={handleImageError}
      />
      <Body>
        <ItemName>{data.name}</ItemName>
        <ItemPrices>{getPrices(data.price, data.other_prices)}</ItemPrices>

        <Shadow />

        {data.description ? (
          <>
            <ItemDescription
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
            <Shadow />
          </>
        ) : null}
        <ItemName>{t[language].menu.allergies.title}</ItemName>
        {data.allergies.length > 0 ? (
          <List>
            {data.allergies.map((allergy) => (
              <StyleListItem key={allergy}>
                <StyledListItemText
                  primary={t[language].menu.allergies[allergy.split(".")[1]]}
                />
              </StyleListItem>
            ))}
          </List>
        ) : (
          <ItemDescription>
            {t[language].menu.allergies.no_allergens}
          </ItemDescription>
        )}
        <Shadow />
        <ItemName>{t[language].menu.nutrition.title}</ItemName>
        {Object.keys(data.nutrition).length > 0 ? (
          <StyledTable size={"small"} aria-label={"nutrition table"}>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell> </StyledTableHeaderCell>
                <StyledTableHeaderCell align={"center"}>
                  100g
                </StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data.nutrition).map((n, i) => (
                <TableRow key={i}>
                  <StyledTableCell align={"right"}>
                    {t[language].menu.nutrition[n]}
                  </StyledTableCell>
                  <StyledTableCell align={"center"}>
                    {data.nutrition[n]}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        ) : (
          <ItemDescription>
            {t[language].menu.nutrition.no_nutrition_info}
          </ItemDescription>
        )}
        <StyledFabExtended onClick={onClose}>
          <StyledArrow />
          <ButtonText>{t[language].menu.buttons.back}</ButtonText>
        </StyledFabExtended>
      </Body>
    </Wrapper>
  );
};

Item.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default Item;
