import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Fab } from "@material-ui/core";
import styled from "styled-components";
import { getSearchParam } from "../../helpers";
import t from "../../assets/translations";
import backgroundV from "../../assets/images/chef_house_bg_1080x1920.jpg";
import backgroundH from "../../assets/images/chef_house_bg_1280x800.jpg";

const Wrapper = styled.div`
  overflow-y: auto;
  min-height: calc(100vh - 24px);
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${backgroundV});
  padding: 12px 12.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (orientation: landscape) {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${backgroundH});
  }
`;

const ImageWrapper = styled.div`
  height: 175px;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    max-width: 100%;
    height: 100%;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 12px auto;
`;

const ButtonsRow = styled(Row)``;
const EnjoyRow = styled(Row)`
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledFabExtended = styled(Fab).attrs({ variant: "extended" })`
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.white};
  box-shadow: none;
  margin: 12px auto;
  display: flex;
  width: 145px;
`;

const ButtonText = styled(Typography)`
  ${(props) => props.theme.drawerListFont};
  font-size: 12px;
  color: ${(props) => props.theme.white};
  margin-left: 6px;
`;

const EnjoyText = styled(Typography)`
  font-family: HelveticaBold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2px;
  color: ${(props) => props.theme.white};
`;

const MediascreenInfo = styled(Typography)`
  font-family: Helvetica;
  font-size: 12px;
  color: ${(props) => props.theme.white};
  position: absolute;
  bottom: 2px;
`;

const LandingPage = ({
  language,
  fetchMenu,
  selectLanguage,
  toggleLandingPage,
}) => {
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    fetchMenu(getSearchParam("menu"), language).then(({ data }) => {
      setMenuData(data);
    });
  }, [fetchMenu, language]);

  return (
    <Wrapper>
      {menuData ? (
        <>
          {menuData.image ? (
            <ImageWrapper>
              <img
                src={`https://plataforma.ementasdigitais.pt/uploads/images/${menuData.image}`}
                alt="logo"
              />
            </ImageWrapper>
          ) : null}
          <ButtonsRow>
            {menuData.languages.map((l) => (
              <StyledFabExtended
                onClick={() => {
                  selectLanguage(l);
                  toggleLandingPage(false);
                }}
                key={l}
              >
                <ButtonText>{t[l].menu.landing_page.button}</ButtonText>
              </StyledFabExtended>
            ))}
          </ButtonsRow>
          <EnjoyRow>
            {menuData.languages.map((l, i) => (
              <EnjoyText key={l}>{t[l].menu.landing_page.enjoy}</EnjoyText>
            ))}
          </EnjoyRow>
        </>
      ) : null}
      <MediascreenInfo>Powered by MediaScreen.pt - 913723727</MediascreenInfo>
    </Wrapper>
  );
};

LandingPage.propTypes = {
  language: PropTypes.string.isRequired,
  fetchMenu: PropTypes.func.isRequired,
  selectLanguage: PropTypes.func.isRequired,
  toggleLandingPage: PropTypes.func.isRequired,
};

export default LandingPage;
