import { combineReducers } from "redux";

function isOpen(state = true, action) {
  switch (action.type) {
    case "OPEN_DRAWER":
      return true;
    case "CLOSE_DRAWER":
      return false;
    default:
      return state;
  }
}

function selected(state = { id: -1, tag: "" }, action) {
  switch (action.type) {
    case "SET_SELECTED_CATEGORY":
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  isOpen,
  selected,
});
