const MenuscreenTheme = {
  white: "#FFFFFF",
  black: "#000000",
  background: "#EEEEEE",
  lightGrey: "#B6B8BA",
  drawerListFont:
    "font-size: 18px; font-family: Helvetica; letter-spacing: 2px; text-transform: uppercase;",
  headerFont:
    "font-size: 24px; font-family: HelveticaBold; letter-spacing: 0px; text-transform: capitalize;",
  itemTitleFont:
    "font-size: 18px; font-family: HelveticaBold; font-weight: 900; font-style: oblique; text-align: center; margin-top: 6px;",
  itemBodyFont:
    "font-size: 12px; font-family: Helvetica; text-align: center; margin-top: 6px;",
  filterSectionFont:
    "font-family: HelveticaBold; letter-spacing: 0px; text-transform: uppercase;",
  filterListFont:
    "font-size: 14px; font-family: Helvetica; letter-spacing: 2px; text-transform: uppercase;",
};

export default MenuscreenTheme;
