export function toggleDrawer(open) {
  return {
    type: open ? "OPEN_DRAWER" : "CLOSE_DRAWER",
  };
}

export function selectCategory(category) {
  return {
    type: "SET_SELECTED_CATEGORY",
    payload: category,
  };
}
