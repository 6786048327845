import { connect } from "react-redux";
import Item from "./Item";

const mapStateToProps = (state, ownProps) => ({
  language: state.language.selected,
});

const mapDispatchToProps = {};

const ItemContainer = connect(mapStateToProps, mapDispatchToProps)(Item);

export default ItemContainer;
