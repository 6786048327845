import { connect } from "react-redux";
import LandingPage from "./LandingPage";
import { fetchMenu } from "../../redux/requests/actions";
import { selectLanguage } from "../../redux/language/actions";
import { toggleLandingPage } from "../../redux/landingPage/actions";

const mapStateToProps = (state, ownProps) => ({
  language: state.language.selected,
});

const mapDispatchToProps = {
  fetchMenu,
  selectLanguage,
  toggleLandingPage,
};

const LandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage);

export default LandingPageContainer;
