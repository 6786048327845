const FETCH_MENU = "FETCH_MENU";
const FETCH_CATEGORY_ITEMS = "FETCH_CATEGORY_ITEMS";

export const fetchMenu = (menu, language) => ({
  type: FETCH_MENU,
  request: {
    url: `/${language}/menu/${menu}`,
  },
  meta: {
    cache: true,
  },
});

const now = new Date(),
  year = now.getFullYear();

let month = now.getMonth() + 1;
month = month.toString().length === 1 ? "0" + month : month;

const day =
    now.getDate().toString().length === 1 ? "0" + now.getDate() : now.getDate(),
  dateStr = year + "-" + month + "-" + day;

export const fetchCategoryItems = (menu, id, isDaily, type, language) => ({
  type: FETCH_CATEGORY_ITEMS,
  request: {
    url: isDaily
      ? `/${language}/menu/${menu}/scheduled_items/${dateStr}`
      : type === "manual_managed"
      ? `/${language}/menu/${menu}/sections/${id}/items`
      : `/${language}/menu/${menu}/category/${id}/items`,
  },
  meta: {
    cache: isDaily ? 120 : 900,
  },
});
