import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import * as filters from "./filters";
import t from "../../assets/translations";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const StyledSwipeableDrawer = styled(SwipeableDrawer).attrs({
  anchor: "right",
  disableBackdropTransition: !iOS,
  disableDiscovery: iOS,
})`
  .MuiDrawer-paperAnchorRight {
    padding: 12px;
    height: 100%;
    width: 50%;
  }
`;

const FilterSectionName = styled(Typography).attrs({ variant: "body1" })`
  padding-top: 8px;
  ${(props) => props.theme.filterSectionFont};
`;

const StyledList = styled(List)``;

const StyledListItem = styled(ListItem)`
  &&& * {
    ${(props) => props.theme.filterListFont};
    text-align: center;
  }
  justify-content: center;
  color: ${(props) => props.theme.lightGrey};

  > div {
    flex: 0 1 auto;
  }

  &.Mui-selected {
    color: ${(props) => props.theme.black};
    background-color: ${(props) => props.theme.white};

    &:hover {
      background-color: ${(props) => props.theme.white};
    }

    span {
      border-bottom: 3px solid ${(props) => props.theme.black};
    }
  }
`;

const SytledListItemText = styled(ListItemText)`
  .MuiTypography-displayBlock {
    display: unset;
  }
`;

const FilterItems = ({
  toggleFilter,
  selectFilter,
  isOpen,
  selectedFilter,
  selectedCategoryTag,
  language,
}) => {
  return (
    <StyledSwipeableDrawer
      open={isOpen}
      onClose={() => toggleFilter(false)}
      onOpen={() => toggleFilter(true)}
      disableSwipeToOpen={false}
    >
      <FilterSectionName>{t[language].menu.filters.type}</FilterSectionName>
      <StyledList>
        {filters[`${selectedCategoryTag}TypeTags`].map((type) => (
          <StyledListItem
            selected={selectedFilter.type === type}
            key={type}
            onClick={() => selectFilter({ ...selectedFilter, type })}
          >
            <SytledListItemText
              primary={t[language].menu[`${selectedCategoryTag}_types`][type]}
            />
          </StyledListItem>
        ))}
      </StyledList>

      {selectedCategoryTag === "wines" ? (
        <>
          <FilterSectionName>
            {t[language].menu.filters.region}
          </FilterSectionName>
          <StyledList>
            {filters.winesRegionTags.map((region) => (
              <StyledListItem
                selected={selectedFilter.region === region}
                key={region}
                onClick={() => selectFilter({ ...selectedFilter, region })}
              >
                <SytledListItemText
                  primary={t[language].menu.wines_regions[region]}
                />
              </StyledListItem>
            ))}
          </StyledList>
        </>
      ) : null}
    </StyledSwipeableDrawer>
  );
};

FilterItems.propTypes = {
  toggleFilter: PropTypes.func.isRequired,
  selectFilter: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedFilter: PropTypes.object,
  selectedCategoryTag: PropTypes.string,
  language: PropTypes.string.isRequired,
};

export default FilterItems;
