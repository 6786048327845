import { createGlobalStyle } from "styled-components";

import HelveticaBold from "./Helvetica-Bold.ttf";
import HelveticaBoldOblique from "./Helvetica-BoldOblique.ttf";
import HelveticaLight from "./Helvetica-Light.ttf";
import HelveticaLightOblique from "./Helvetica-LightOblique.ttf";
import HelveticaOblique from "./Helvetica-Oblique.ttf";
import Helvetica from "./Helvetica.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'HelveticaBold';
        src: url(${HelveticaBold}) format('TrueType');
        font-weight: bold;
        font-style: normal;
	}
	
    @font-face {
        font-family: 'HelveticaBoldOblique';
        src: url(${HelveticaBoldOblique}) format('TrueType');
        font-weight: bold;
        font-style: oblique;
    }
	
    @font-face {
        font-family: 'HelveticaLight';
        src: url(${HelveticaLight}) format('TrueType');
        font-weight: lighter;
        font-style: normal;
    }
	
    @font-face {
        font-family: 'HelveticaLightOblique';
        src: url(${HelveticaLightOblique}) format('TrueType');
        font-weight: lighter;
        font-style: oblique;
	}
	
    @font-face {
        font-family: 'HelveticaOblique';
        src: url(${HelveticaOblique}) format('TrueType');
        font-weight: normal;
        font-style: oblique;
    }
	
    @font-face {
        font-family: 'Helvetica';
        src: url(${Helvetica}) format('TrueType');
        font-weight: normal;
        font-style: normal;
	}
`;
