import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TouchScrollable } from "react-scrolllock";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import shadow from "../../assets/images/shadow.png";
import { getSearchParam } from "../../helpers";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const StyledSwipeableDrawer = styled(SwipeableDrawer).attrs({
  anchor: "left",
  disableBackdropTransition: !iOS,
  disableDiscovery: iOS,
})`
  .MuiDrawer-paperAnchorLeft {
    padding: 12px;
    right: 0;
    height: 100%;
  }
`;

const StyleIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
  }
  .MuiIconButton-label {
    justify-content: flex-end;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  @media (orientation: landscape) {
    &.MuiIconButton-root {
      top: 12px;
      right: 24px;
    }
  }
`;

const ScrollWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media (orientation: landscape) {
    padding-bottom: 70px;
  }
`;

const StyledList = styled(List)`
  //   overflow-y: scroll;

  //   @media (orientation: landscape) {
  //     padding-bottom: 70px;
  //   }
`;

const StyledListItem = styled(ListItem)`
  &&& * {
    ${(props) => props.theme.drawerListFont};
    text-align: center;
  }
  justify-content: center;
  color: ${(props) => props.theme.lightGrey};

  > div {
    flex: 0 1 auto;
  }

  &.Mui-selected {
    color: ${(props) => props.theme.black};
    background-color: ${(props) => props.theme.white};

    &:hover {
      background-color: ${(props) => props.theme.white};
    }

    span {
      border-bottom: 3px solid ${(props) => props.theme.black};
    }
  }
`;

const SytledListItemText = styled(ListItemText)`
  .MuiTypography-displayBlock {
    display: unset;
  }
`;

const ImageWrapper = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    max-width: 100%;
    height: 100%;
  }
`;

const Shadow = styled.img.attrs({ src: shadow })`
  width: 120px;
  display: flex;
  align-self: center;
  margin: 0 auto;
`;

const MediascreenInfo = styled(Typography)`
  font-family: Helvetica;
  font-size: 12px;
  color: ${(props) => props.theme.lightGrey};
  margin: 12px 0;
  text-align: center;
`;

const MenuCategories = ({
  image,
  categories,
  isOpen,
  toggleDrawer,
  selected,
  selectCategory,
  fetchCategoryItems,
  resetFilter,
  language,
}) => {
  const handleClick = (c) => {
    if (
      selected.id !==
      (c.is_daily_config ? "daily" : c.category ? c.category.id : c.id)
    ) {
      fetchCategoryItems(
        getSearchParam("menu"),
        c.is_daily_config ? "" : c.category ? c.category.id : c.id,
        c.is_daily_config,
        c.type,
        language
      );
      selectCategory({
        id: c.is_daily_config ? "daily" : c.category ? c.category.id : c.id,
        tag: c.category ? c.category.tag : c.tag,
      });
      resetFilter();
    }

    toggleDrawer(false);
  };

  return (
    <StyledSwipeableDrawer
      open={isOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      disableSwipeToOpen={false}
    >
      <StyleIconButton onClick={() => toggleDrawer(false)} aria-label="menu">
        <Close />
      </StyleIconButton>
      {image ? (
        <>
          <ImageWrapper>
            <img
              src={`https://plataforma.ementasdigitais.pt/uploads/images/${image}`}
              alt="logo"
            />
          </ImageWrapper>
          <Shadow />
        </>
      ) : null}
      <TouchScrollable>
        <ScrollWrapper>
          <StyledList>
            {categories.map((category) =>
              category.category ? (
                category.type !== "tree" ? (
                  <StyledListItem
                    key={category.id}
                    onClick={() => handleClick(category)}
                    selected={
                      selected.id ===
                      (category.is_daily_config
                        ? "daily"
                        : category.category
                        ? category.category.id
                        : category.id)
                    }
                  >
                    <SytledListItemText primary={category.name} />
                  </StyledListItem>
                ) : (
                  category.category.children.map((c) => (
                    <StyledListItem
                      key={c.id}
                      onClick={() => handleClick(c)}
                      selected={selected.id === c.id}
                    >
                      <SytledListItemText primary={c.name} />
                    </StyledListItem>
                  ))
                )
              ) : (
                <StyledListItem
                  key={category.id}
                  onClick={() =>
                    handleClick({ ...category, type: "manual_managed" })
                  }
                  selected={selected.id === category.id}
                >
                  <SytledListItemText primary={category.name} />
                </StyledListItem>
              )
            )}
          </StyledList>
          <MediascreenInfo>
            Powered by MediaScreen.pt - 913723727
          </MediascreenInfo>
        </ScrollWrapper>
      </TouchScrollable>
    </StyledSwipeableDrawer>
  );
};

MenuCategories.propTypes = {
  image: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  fetchCategoryItems: PropTypes.func,
  resetFilter: PropTypes.func,
  language: PropTypes.string.isRequired,
};

export default MenuCategories;
