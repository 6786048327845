import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { handleRequests } from "@redux-requests/core";
import axios from "axios";
import { createDriver } from "@redux-requests/axios";
import { getSearchParam } from "../helpers";

import drawer from "./drawer/reducer";
import filter from "./filter/reducer";
import item from "./item/reducer";
import language from "./language/reducer";
import landingPage from "./landingPage/reducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createDriver(
    axios.create({
      baseURL: "https://plataforma.ementasdigitais.pt/api",
      headers: {
        "X-Auth-Token": getSearchParam("apiToken"),
      },
    })
  ),
});

const rootReducer = combineReducers({
  requests: requestsReducer,
  drawer,
  filter,
  item,
  language,
  landingPage,
});

export const store = createStore(
  persistReducer(
    {
      key: "root",
      storage,
      whitelist: ["requests"],
    },
    rootReducer
  ),
  composeEnhancers(applyMiddleware(...requestsMiddleware))
);

export const persistor = persistStore(store);
