import { connect } from "react-redux";
import { toggleDrawer, selectCategory } from "../../redux/drawer/actions";
import { resetFilter } from "../../redux/filter/actions";
import { fetchCategoryItems } from "../../redux/requests/actions";
import MenuCategories from "./MenuCategories";

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.drawer.isOpen,
  selected: state.drawer.selected,
  language: state.language.selected,
});

const mapDispatchToProps = {
  toggleDrawer,
  selectCategory,
  fetchCategoryItems,
  resetFilter,
};

const MenuCategoriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuCategories);

export default MenuCategoriesContainer;
