import { connect } from "react-redux";
import MenuItems from "./MenuItems";
import { toggleFilter } from "../../redux/filter/actions";
import { selectItem } from "../../redux/item/actions";
import { getQuery } from "@redux-requests/core";

const mapStateToProps = (state, ownProps) => ({
  isDrawerOpen: state.drawer.isOpen,
  categoryItems: getQuery(state, {
    type: "FETCH_CATEGORY_ITEMS",
    defaultData: [],
  }).data,
  selectedCategory: state.drawer.selected,
  selectedFilter: state.filter.selected,
  selectedItem: state.item.selected,
  language: state.language.selected,
});

const mapDispatchToProps = {
  toggleFilter,
  selectItem,
};

const MenuItemsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItems);

export default MenuItemsContainer;
