export const winesTypeTags = [
  "all_types",
  "rose",
  "red_wine",
  "white_wine",
  "green_wine",
  "espumante",
  "licoroso",
];

export const winesRegionTags = [
  "all_regions",
  "minho",
  "transmontano_douro",
  "beiras",
  "lisboa",
  "tejo",
  "alentejo",
  "algarve",
  "setubal",
  "madeira",
  "acores",
  "mundo",
];

export const sakeTypeTags = [
  "all_types",
  "jummai",
  "ginjo",
  "daiginjo",
  "honjozo",
];
