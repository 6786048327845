export function toggleFilter(open) {
  return {
    type: open ? "OPEN_FILTER" : "CLOSE_FILTER",
  };
}

export function selectFilter(tag) {
  return {
    type: "SET_SELECTED_FILTER",
    payload: tag,
  };
}

export function resetFilter() {
  return {
    type: "RESET_FILTER",
  };
}
