import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { get } from "lodash-es";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
} from "@material-ui/core";
import { Menu, InfoOutlined, Close } from "@material-ui/icons";
import MenuCategories from "../MenuCategories";
import { getSearchParam } from "../../helpers";
import t from "../../assets/translations";

const Bar = styled(AppBar).attrs({ position: "sticky" })`
  &.MuiAppBar-root {
    margin-bottom: -12px;
  }

  &.MuiAppBar-colorPrimary {
    background-color: ${(props) => props.theme.white};
    color: ${(props) => props.theme.lightGrey};
  }

  @media (orientation: landscape) {
    &.MuiAppBar-root {
      padding-top: 6px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

const MenuName = styled(Typography).attrs({ variant: "h6" })`
  ${(props) => props.theme.headerFont};
  color: ${(props) => props.theme.black};
`;

const RightSide = styled.div`
  margin-left: auto;
  margin-right: -12px;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0 12px 24px;
  }
`;

const DialogText = styled(Typography)`
  color: ${(props) => props.theme.black};
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  font-style: oblique;
`;

const AppHeader = ({ fetchMenu, toggleDrawer, selectItem, language }) => {
  const [menuData, setMenuData] = useState(null);
  const [isDialogOpen, toggleDialog] = useState(false);

  useEffect(() => {
    fetchMenu(getSearchParam("menu"), language).then(({ data }) => {
      setMenuData(data);
    });
  }, [fetchMenu, language]);

  return (
    <>
      <Bar>
        <Toolbar>
          <IconButton
            onClick={() => {
              toggleDrawer(true);
              selectItem(null);
            }}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <Menu />
          </IconButton>
          <MenuName>{get(menuData, "name", "")}</MenuName>
          <RightSide>
            <IconButton
              onClick={() => toggleDialog(!isDialogOpen)}
              color="inherit"
              aria-label="info"
            >
              <InfoOutlined />
              <StyledDialog
                open={isDialogOpen}
                onClose={() => toggleDialog(false)}
              >
                <>
                  <RightSide>
                    <IconButton
                      onClick={() => toggleDialog()}
                      color="inherit"
                      aria-label="close"
                    >
                      <Close />
                    </IconButton>
                  </RightSide>

                  <DialogText>
                    {t[language].menu.disclaimers.general}
                  </DialogText>
                </>
              </StyledDialog>
            </IconButton>
          </RightSide>
        </Toolbar>
      </Bar>
      <MenuCategories
        image={get(menuData, "image", "")}
        categories={get(menuData, "sections", [])}
      />
    </>
  );
};

AppHeader.propTypes = {
  fetchMenu: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default AppHeader;
