import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  root: {
    flexGrow: 1,
  },
  palette: {
    primary: {
      main: "#2d4275",
    },
    secondary: {
      main: "#15D4F1",
    },
    error: {
      main: "#FF0032",
    },
  },
});
